export function useWeather() {
  function getWeatherIcon(weather) {
    switch (weather) {
      case "sun":
        return "/images/icons/animated/day.svg";
      case "rain":
        return "/images/icons/animated/rainy-6.svg";
      case "drizzle":
        return "/images/icons/animated/rainy-3.svg";
      case "snow":
        return "/images/icons/animated/snowy-6.svg";
      case "fog":
        return "/images/icons/animated/cloudy.svg";
      default:
        return;
    }
  }
  function getWeatherType(weather) {
    switch (weather) {
      case "sun":
        return "Sunny";
      case "rain":
        return "Rainy";
      case "drizzle":
        return "Drizzle";
      case "snow":
        return "Snowy";
      case "fog":
        return "Foggy";
      default:
        return;
    }
  }
  function getWeatherBg(weather) {
    switch (weather) {
      case "sun":
        return "url(/images/sunny-1.jpg)";
      case "rain":
        return "url(/images/rainy-1.jpg)";
      case "drizzle":
        return "url(/images/drizzle.jpg)";
      case "snow":
        return "url(/images/snowy.jpg)";
      case "fog":
        return "url(/images/cloudy.jpg)";
      default:
        return;
    }
  }
  return { getWeatherIcon, getWeatherType, getWeatherBg };
}
