import { Button, Paper, TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import dayjs from "dayjs";

export default function WeatherForm({ formik }) {
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = formik;

  return (
    <div className="md:w-6/12 w-full md:h-96 h-max rounded-lg flex">
      <Paper className="lg:w-96 w-3/4 md:h-full h-max p-8 m-auto flex shadow-inner" variant="outlined">
        <form onSubmit={handleSubmit} className="flex flex-col text-base my-auto">
          <div className="mb-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  closeOnSelect={true}
                  name="date"
                  type="date"
                  label="Date"
                  variant="outlined"
                  format="DD/MM/YYYY"
                  value={dayjs(values.date)}
                  onChange={(e) => setFieldValue("date", dayjs(e).format("YYYY-MM-DD"), true)}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: Boolean(touched.date) && Boolean(errors.date),
                      helperText: touched.date && errors.date,
                      fullWidth: true,
                      onBlur: handleBlur,
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="my-2 flex gap-2">
            <TextField
              id="temp_min"
              type="number"
              name="temp_min"
              label="Min Temp °C"
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.temp_min && Boolean(errors.temp_min)}
              helperText={touched.temp_min && errors.temp_min}
              value={values.temp_min}
            />

            <TextField
              id="temp_max"
              name="temp_max"
              label="Max Temp °C"
              variant="outlined"
              size="small"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.temp_max && Boolean(errors.temp_max)}
              helperText={touched.temp_max && errors.temp_max}
              value={values.temp_max}
            />
          </div>

          <div className="my-2 flex gap-2">
            <TextField
              id="wind_speed"
              name="wind_speed"
              label="Wind Speed"
              variant="outlined"
              size="small"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.wind_speed && Boolean(errors.wind_speed)}
              helperText={touched.wind_speed && errors.wind_speed}
              value={values.wind_speed}
            />
            <TextField
              id="precipitation"
              name="precipitation"
              label="Precipitation"
              variant="outlined"
              size="small"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.precipitation && Boolean(errors.precipitation)}
              helperText={touched.precipitation && errors.precipitation}
              value={values.precipitation}
            />
          </div>

          <div className="mt-4 text-end">
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}
