import { Paper, Typography } from "@mui/material";
import React from "react";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import AirIcon from "@mui/icons-material/Air";
import SkeletonCard from "./Skeleton";
import dayjs from "dayjs";
import { useWeather } from "../Hooks/useWeather";
export default function WeatherCard({ formValues, response, loading }) {
  const { getWeatherIcon, getWeatherType, getWeatherBg } = useWeather();
  if (!formValues || !response) {
    return (
      <div className="md:w-6/12 w-full md:h-96 h-80 rounded-lg flex">
        <Typography
          variant="h6"
          component="h6"
          className="text-center text-md decoration-violet-50 w-2/3 h-max"
          sx={{ m: "auto" }}
        >
          Please submit the form to get a weather forecast
        </Typography>
      </div>
    );
  }
  if (loading) return <SkeletonCard />;
  return (
    <div className="md:w-6/12 w-full md:h-96 h-full rounded-lg flex">
      <Paper
        elevation={24}
        className="m-auto h-full md:w-2/3 w-3/4 flex flex-col"
        sx={{
          background: getWeatherBg(response?.weather),
          backgroundPosition: "center",
          "-webkit-backdrop-filter": "blur(5px)",
          backdropFilter: "blur(5px)",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full md:h-full h-max p-4 flex flex-col rounded-sm bg-half-dark-reverse">
          <div className="w-full flex justify-between">
            <div className="flex flex-col items-start">
              <Typography variant="p" className="text-lg text-right text-white font-bold">
                Location
              </Typography>
              <Typography variant="p" className="text-md text-right text-white font-bold">
                Kolkata, WB
              </Typography>
              <Typography variant="p" className="text-md text-right text-white font-bold">
                India
              </Typography>
            </div>
            <div className="flex flex-col items-end">
              <Typography variant="p" className="text-lg text-right text-white font-bold">
                Weather
              </Typography>
              <Typography variant="p" className="text-md text-right text-white font-bold">
                {response?.weekday}, {dayjs().format("h:mm A")}
              </Typography>
              <Typography variant="p" className="text-md text-right text-white font-bold">
                {getWeatherType(response?.weather)}
              </Typography>
            </div>
          </div>
          <div className="w-full h-1/3">
            <div className="w-full h-full flex flex-col items-center justify-center gap-2">
              {/* {getWeatherIcon(response?.weather)} */}
              {/* <SunnyIcon /> */}
              <img src={getWeatherIcon(response?.weather)} className="w-1/3" alt="rainy" />
              <Typography variant="p" className="text-3xl text-center text-bold text-white font-bold">
                {(formValues?.temp_min + formValues?.temp_max) / 2}°C
              </Typography>
            </div>
          </div>
          <div className="w-full h-1/3 mt-auto">
            <div className="w-full h-full flex justify-center gap-2">
              <div className="w-1/3 flex flex-col justify-center items-center gap-2 rounded-md bg-half-dark mb-4 py-1">
                <ThermostatIcon fontSize="large" sx={{ color: "#ff5e5e" }} />
                <Typography variant="p" className="text-sm text-center text-white">
                  {(formValues?.temp_max - formValues?.temp_min) / 2}°C
                </Typography>
                <Typography variant="p" className="text-sm text-center text-white">
                  Temperature
                </Typography>
              </div>
              <div className="w-1/3 flex flex-col justify-center items-center gap-2 rounded-md bg-half-dark mt-4  py-1">
                <ThunderstormIcon fontSize="large" sx={{ color: "#003961" }} />
                <Typography variant="p" className="text-sm text-center text-white">
                  {formValues?.precipitation}%
                </Typography>
                <Typography variant="p" className="text-sm text-center text-white">
                  Precipitation
                </Typography>
              </div>
              <div className="w-1/3 flex flex-col justify-center items-center gap-2 rounded-md bg-half-dark mb-4 py-1">
                <AirIcon fontSize="large" sx={{ color: "#009B94" }} />
                <Typography variant="p" className="text-sm text-center text-white">
                  {formValues?.wind_speed} km/h
                </Typography>
                <Typography variant="p" className="text-sm text-center text-white">
                  Wind
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
