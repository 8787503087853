import * as yup from "yup";

const Date = (name = "Date") => yup.date().nullable().required(`${name} is required`);
const POSITIVE_NUMBER = (type = "Field") => yup.number().required(`${type} is required`);

export const FormValidator = yup.object({
  date: Date("Date"),
  temp_min: POSITIVE_NUMBER("Min Temperature"),
  temp_max: POSITIVE_NUMBER("Max Temperature"),
  wind_speed: POSITIVE_NUMBER("Wind Speed").min(0, "Wind Speed cannot be negative"),
  precipitation: POSITIVE_NUMBER("Precipitation").min(0, "Precipitation cannot be negative"),
});
