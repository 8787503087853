import Paper from "@mui/material/Paper";
import WeatherForm from "./WeatherForm";
import WeatherCard from "./WeatherCard";
import { Typography } from "@mui/material";
import { useWeatherForecast } from "../Hooks/useWeatherForecast";

export default function MainPage() {
  const { formValues, response, formik } = useWeatherForecast();

  return (
    <div className="w-full h-screen flex">
      <Paper
        sx={{
          height: "auto",
          width: "88%",
          borderRadius: "0.5rem!important",
          background: "#c5d8d5",
          "@media (max-width: 1050px)": { width: "100%" },
        }}
        className="m-auto flex flex-col"
        elevation={4}
      >
        <Typography className="text-3xl text-center py-6 text-cyan-900" variant="h4">
          Weather Forecast
        </Typography>
        <div className="w-full flex md:flex-row flex-col py-8 md:gap-0 gap-8">
          <WeatherForm formik={formik} />
          <WeatherCard formValues={formValues} response={response} loading={formik.isSubmitting} />
        </div>
      </Paper>
    </div>
  );
}
