import { Paper, Skeleton, Typography } from "@mui/material";

export default function SkeletonCard() {
  return (
    <div className="md:w-6/12 w-full md:h-96 h-80 rounded-lg flex">
      <Paper elevation={12} className="m-auto h-full lg:w-2/3 w-3/4 p-4 flex flex-col">
        <div className=" flex justify-between">
          <div className="flex flex-col items-start">
            <Typography variant="p" className="text-lg text-right text-grey-600">
              <Skeleton variant="text" width={100} />
            </Typography>
            <Typography variant="p" className="text-md text-right text-grey-600">
              <Skeleton variant="text" width={100} />
            </Typography>
            <Typography variant="p" className="text-md text-right text-grey-600">
              <Skeleton variant="text" width={100} />
            </Typography>
          </div>
          <div className="flex flex-col items-end">
            <Typography variant="p" className="text-lg text-right text-grey-600">
              <Skeleton variant="text" width={100} />
            </Typography>
            <Typography variant="p" className="text-md text-right text-grey-600">
              <Skeleton variant="text" width={100} />
            </Typography>
            <Typography variant="p" className="text-md text-right text-grey-600">
              <Skeleton variant="text" width={100} />
            </Typography>
          </div>
        </div>
        <div className="w-full h-1/3">
          <div className="w-full h-full flex flex-col items-center justify-center gap-2">
            <Skeleton variant="circular" width={80} height={100} />
            <Typography variant="p" className="text-3xl text-center text-bold text-stone-500 font-bold">
              <Skeleton variant="rounded" width={100} />
            </Typography>
          </div>
        </div>
        <div className="w-full h-1/3 mt-auto">
          <div className="w-full h-full flex justify-center gap-2">
            <div className="w-1/3 flex flex-col justify-center items-center gap-2 rounded-md  mb-4">
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="rounded" width={100} />
              <Skeleton variant="rounded" width={100} />
            </div>
            <div className="w-1/3 flex flex-col justify-center items-center gap-2 rounded-md  mt-4">
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="rounded" width={100} />
              <Skeleton variant="rounded" width={100} />
            </div>
            <div className="w-1/3 flex flex-col justify-center items-center gap-2 rounded-md  mb-4">
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="rounded" width={100} />
              <Skeleton variant="rounded" width={100} />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
