import { useState } from "react";
import { getWeatherPrediction } from "../Service/weather.service";
import { useFormik } from "formik";
import { FormValidator } from "../Shared/Validation";

export function useWeatherForecast() {
  const [formValues, setFormValues] = useState(null);
  const [response, setResponse] = useState(null);
  const fetchWeather = (values) => {
    getWeatherPrediction(values)
      .then((respose) => {
        setFormValues(values);
        setResponse(respose);
      })
      .catch((error) => console.log(error))
      .finally(() => formik.setSubmitting(false));
  };
  const formik = useFormik({
    initialValues: {
      date: null,
      temp_min: "",
      temp_max: "",
      wind_speed: "",
      precipitation: "",
    },
    validationSchema: FormValidator,
    onSubmit: (values) => {
      // console.log(values);
      fetchWeather(values);
    },
  });
  return { formValues, response, fetchWeather, formik };
}
